.submit-button {
  cursor: pointer;
  border: none;
  background-color: var(--secondary);
  border-radius: 3px;
  padding: 15px;
  align-self: stretch;
  /* Текст */
  color: var(--on-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  /* Анимация */
  background-image: none;
  transition: opacity.3s, transform 0.3s, color 0.3s, background-color 0.3s;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button:active {
  transform: scale(0.9);
}

.submit-button:disabled {
  background-color: var(--button-disabled);
  color: var(--on-button-disabled);
  pointer-events: none;
}

.submit-button_type_loading {
  pointer-events: none;
  color: transparent;
  background-image: url(../../../images/icon__loading.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
}
