.navigation {
  color: var(--on-surface);
}

@media (min-width: 1280px) {
  .navigation {
    width: 100%;
  }
}

.navigation__open-button {
  /* Сброс стилей */
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* Размеры */
  width: 44px;
  aspect-ratio: 1;
  /* Иконка */
  background-image: url(../../../images/icon__menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* Анимация */
  transition: opacity 0.3s, transform 0.3s;
}

.navigation__open-button:hover {
  opacity: 0.8;
}

.navigation__open-button:active {
  transform: scale(0.9);
}

@media (min-width: 1280px) {
  .navigation__open-button {
    display: none;
  }
}

.navigation_themed .navigation__open-button {
  background-image: url(../../../images/icon__menu_white.svg);
}

/* Всё меню, включая оверлей */
.navigation__menu {
  /* Положение */
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* Оверлей */
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  /* Анимация */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.navigation__menu_opened {
  /* Анимация */
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1280px) {
  .navigation__menu {
    /* Сброс мобильной версии */
    position: static;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    backdrop-filter: none;
  }
}

.navigation__panel {
  /* Сброс */
  margin: 0;
  /* Размеры */
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 520px;
  padding: 144px 10px 46px;
  /* Положение */
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  /* Анимация */
  transition: transform 0.3s;
  /* Фон */
  background-color: var(--surface);
}

.navigation__menu_opened .navigation__panel {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .navigation__panel {
    padding: 160px 90px 90px;
  }
}

@media (min-width: 1280px) {
  .navigation__panel {
    /* Сброс мобильной версии */
    position: static;
    transform: translateX(0);
    background-color: transparent;
    max-width: none;
    padding: 0;
  }
}

.navigation__close-button {
  /* Сброс */
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* Позиционирование */
  position: absolute;
  top: 13px;
  right: 13px;
  /* Размеры */
  width: 31px;
  aspect-ratio: 1;
  /* Иконка */
  background-image: url(../../../images/icon__close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* Анимация */
  transition: opacity 0.3s, transform 0.3s;
}

.navigation__close-button:hover {
  opacity: 0.8;
}

.navigation__close-button:active {
  transform: scale(0.9);
}

@media (min-width: 768px) {
  .navigation__close-button {
    top: 30px;
    right: 30px;
  }
}

@media (min-width: 1280px) {
  .navigation__close-button {
    display: none;
  }
}

.navigation__list {
  /* Сброс */
  list-style: none;
  margin: 0;
  padding: 0;
  /* Расположение элементов внутри */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  /* Размер */
  height: 100%;
}

@media (min-width: 768px) {
  .navigation__list {
    gap: 28px;
  }
}

@media (min-width: 1280px) {
  .navigation__list {
    /* Сброс мобильной версии */
    flex-direction: row;
    gap: 16px;
  }
}

.navigation__list-item:last-child {
  margin-top: auto;
}

@media (min-width: 1280px) {
  .navigation__list-item:last-child {
    margin-top: 0;
    margin-left: auto;
  }
}

@media (min-width: 1280px) {
  .navigation__list-item_type_main-page {
    display: none;
  }
}

.navigation__link {
  /* Сброс */
  display: block;
  color: inherit;
  text-decoration: none;
  /* Текст */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  /* Анимация */
  transition: opacity 0.3s;
}

.navigation__link:hover {
  opacity: 0.8;
}

@media (min-width: 1280px) {
  .navigation__link {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.38;
  }

  .navigation_themed .navigation__link {
    color: var(--on-promo);
  }
}

.navigation__link_active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 11px;
}

@media (min-width: 1280px) {
  .navigation__link_active {
    text-decoration: none;
    font-weight: 500;
  }
}

.navigation__link_type_account {
  /* Текст */
  font-size: 14px;
  line-height: 1.14;
}

@media (min-width: 768px) {
  .navigation__link_type_account {
    /* Кнопка */
    padding: 8px 20px;
    border-radius: 20px;
    background-color: var(--account-button);
  }
}

@media (min-width: 1280px) {
  .navigation__link_type_account {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.14;
  }

  .navigation_themed .navigation__link_type_account {
    background-color: var(--promo-variant);
  }
}

@media (min-width: 768px) {
  .navigation__link_active.navigation__link_type_account {
    text-decoration: none;
    outline: 2px solid;
  }
}
