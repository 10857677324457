.portfolio {
  padding-top: 70px;
  color: var(--on-surface);
}

@media (min-width: 768px) {
  .portfolio {
    padding-top: 90px;
  }
}

@media (min-width: 1280px) {
  .portfolio {
    padding-top: 100px;
  }
}

.portfolio__title {
  margin: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: var(--on-surface-variant);
}

@media (min-width: 768px) {
  .portfolio__title {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.11;
  }
}

.portfolio__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.portfolio__list-item {
  border-top: 1px solid var(--on-surface-variant-secondary);
}

.portfolio__list-item:first-of-type {
  border-top: none;
}


.portfolio__link {
  display: block;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.04em;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s;
  padding-block: 20px;
  padding-right: 1.5em;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .portfolio__link {
    font-size: 28px;
    line-height: 1.79;
  }
}

@media (min-width: 1280px) {
  .portfolio__link {
    font-size: 30px;
    line-height: 1.67;
  }
}

.portfolio__link:hover {
  opacity: 0.7;
}

.portfolio__link::after {
  content: '↗';
  position: absolute;
  right: 0;
  top: 20px;
}
