.navtab {
  background-color: var(--background);
  color: var(--on-background);
  padding-block: 15px;
}

.navtab__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 24px;
}

@media (min-width: 768px) {
  .navtab__list {
    column-gap: 40px;
  }
}

.navtab__link {
  display: block;
  padding-block: 10px;
  color: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.45;
  text-decoration-line: underline;
  transition: opacity 0.3s;
}

@media (min-width: 768px) {
  .navtab__link {
    font-size: 13px;
    line-height: 1.23;
  }
}

.navtab__link:hover {
  opacity: 0.7;
}
