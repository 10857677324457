.auth-links {
  color: var(--on-promo);
  margin: 0;
}

.auth-links__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 14px;
}

@media (min-width: 768px) {
  .auth-links__list {
    column-gap: 30px;
  }
}

.auth-links__link {
  /* Сброс */
  display: block;
  color: inherit;
  text-decoration: none;
  /* Размеры */
  padding: 5px 0;
  /* Анимация */
  transition: opacity 0.3s;
  /* Текст */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.6;
}

.auth-links__link:hover {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .auth-links__link {
    padding-block: 8px;
    font-size: 12px;
    line-height: 1.33;
  }
}

.auth-links__link_type_login {
  color: var(--on-primary);
  background-color: var(--primary);
  border-radius: 3px;
  padding-inline: 12px;
}

@media (min-width: 768px) {
  .auth-links__link_type_login {
    padding-inline: 20px;
  }
}
