.movie-card {
  --gap: 14px;
  display: block;
  max-width: 600px;
  padding-block: var(--gap);
  /* Внешний вид */
  background-color: var(--card);
  border-radius: 6px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  color: var(--on-card);
  /* Расположение элементов */
  display: grid;
  column-gap: var(--gap);
  row-gap: var(--gap);
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr auto auto;
  grid-template-areas:
    'name duration'
    'thumbnail thumbnail'
    'button button';
}

@media (min-width: 768px) {
  .movie-card {
    --gap: 18px;
  }
}

.movie-card__name {
  grid-area: name;
  margin: 0;
  margin-left: var(--gap);
  /* Текст */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  /* Перенос слов */
  overflow-wrap: anywhere;
}

@media (min-width: 768px) {
  .movie-card__name {
    font-size: 15px;
    line-height: 1.2;
  }
}

.movie-card__duration {
  grid-area: duration;
  margin: 0;
  margin-right: var(--gap);
  /* Текст */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.8;
  color: var(--on-card-variant);
}

@media (min-width: 768px) {
  .movie-card__duration {
    font-size: 13px;
    line-height: 1.38;
  }
}

.movie-card__link {
  grid-area: thumbnail;
  transition: opacity 0.3s;
}

.movie-card__link:hover {
  opacity: 0.8;
}

.movie-card__thumbnail {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-position: center;
  object-fit: cover;
}

.movie-card__button {
  grid-area: button;
  justify-self: center;
  margin-inline: var(--gap);
}
