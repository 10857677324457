.about-me {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  gap: 40px;
  color: var(--on-surface);
}

@media (min-width: 768px) {
  .about-me {
    grid-template-columns: 1fr 255px;
    gap: 50px;
  }
}

@media (min-width: 768px) {
  .about-me {
    grid-template-columns: 1fr 270px;
    column-gap: 50px;
  }
}

.about-me__photo {
  width: 100%;
  max-width: 270px;
  justify-self: center;
  border-radius: 10px;
  aspect-ratio: 5 / 6;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 768px) {
  .about-me__photo {
    grid-column: -2;
  }
}

@media (min-width: 768px) {
  .about-me__description {
    max-width: 600px;
  }
}

.about-me__name {
  margin: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: -0.04em;
}

@media (min-width: 768px) {
  .about-me__name {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .about-me__name {
    margin-bottom: 18px;
    font-size: 50px;
    line-height: 1.16;
  }
}

.about-me__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.45;
}

@media (min-width: 768px) {
  .about-me__subtitle {
    font-size: 12px;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .about-me__subtitle {
    margin-bottom: 26px;
    font-size: 18px;
    line-height: 1.11;
  }
}

.about-me__text {
  margin: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.45;
}

@media (min-width: 768px) {
  .about-me__text {
    font-size: 12px;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .about-me__text {
    font-size: 14px;
    line-height: 1.57;
  }
}

.about-me__text:last-of-type {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .about-me__text:last-of-type {
    margin-bottom: 90px;
  }
}

@media (min-width: 1280px) {
  .about-me__text:last-of-type {
    margin-bottom: 100px;
  }
}

.about-me__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.about-me__link {
  display: block;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  color: inherit;
  transition: opacity 0.3s;
}

.about-me__link:hover {
  opacity: 0.7;
}
