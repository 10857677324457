/* Файл normalize.css */
@import url(../../vendor/normalize.css);

/* Шрифт Inter*/
@import url(../../vendor/fonts/inter-web/inter.css);

html {
  /* Плавная прокрутка */
  scroll-behavior: smooth;
}

/* Общий стиль для приложения */
.page {
  /* Сглаживание */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  /* Шрифт для всей страницы */
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  /* Цветовая схема */
  --surface: #fff;
  --on-surface: #000;
  --on-surface-variant: #a0a0a0;
  --on-surface-variant-secondary: #e6e6e6;
  --promo: #465dff;
  --promo-variant: #6b7dff;
  --on-promo: #fff;
  --background: #f5f5f5;
  --on-background: #000;
  --background-variant: #f2f2f2;
  --background-variant-secondary: #e8e8e8;
  --primary: #2be080;
  --on-primary: #000;
  --secondary: #ff6838;
  --on-secondary: #fff;
  --footer: #fafafa;
  --on-footer: #a0a0a0;
  --account-button: #f9f9f9;
  --checkbox-disabled: #ebebeb;
  --checkbox-enabled: var(--primary);
  --on-checkbox-disabled: #f5f5f5;
  --on-checkbox-enabled: #fff;
  --card: #fdfdfd;
  --on-card: #000;
  --on-card-variant: #a0a0a0;
  --movie-button: #f9f9f9;
  --on-movie-button: #000;
  --movie-button-done: #ee3465;
  --on-movie-button-done: #fff;
  --error: #ee3465;
  --button-disabled: #f8f8f8;
  --on-button-disabled: #c2c2c2;
  --auth-input: #f9f9f9;
  /* Цвета страницы по умолчанию */
  background-color: var(--surface);
  color: var(--on-surface);
}

/* Фокус интерактивных элементов по умолчанию */
a:focus,
button:focus,
input:focus {
  outline-offset: 5px;
  outline-color: var(--primary);
}

/* Родительский элемент всех компонентов */
.content {
  width: 100%;
  max-width: 1280px;
  min-width: 320px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content__footer {
  margin-top: auto; /* Футер всегда внизу страницы */
}

.content__stretched-element {
  flex-grow: 1; /* Растягивает нужный элемент на всю высоту окна */
}

/* Боковые поля на странице для всех экранов */
.section {
  padding-inline: 18px;
}

@media (min-width: 768px) {
  .section {
    padding-inline: 30px;
  }
}

@media (min-width: 1280px) {
  .section {
    padding-inline: 70px;
  }
}
